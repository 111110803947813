<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          订单管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <b-button variant="success" v-on:click="manualNotify()" class="mx-2">
            <i class="la la-reply"></i>
            批量回调
          </b-button>

          <b-button variant="warning" v-on:click="manualSettle()" class="mx-2">
            <i class="flaticon-stopwatch"></i>
            手动结算
          </b-button>

          <b-dropdown variant="primary" right text="导入导出" class="m-2">
            <b-dropdown-item-button class="rounded" @click="exportOrders">
              <i class="flaticon-download-1 mr-2"></i>
              导出订单
            </b-dropdown-item-button>
          </b-dropdown>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table
            show-select
            fixed-header
            height="70vh"
            v-model="table.selected"
            :headers="table.headers"
            :items="table.desserts"
            :single-select="table.singleSelect"
            :footer-props="table.footer"
            :server-items-length="table.itemsCount"
            :options.sync="table.pagination"
            :no-data-text="table.noDataText"
            :loading="table.loading"
            :loading-text="table.loadingText"
            item-key="orderId"
          >
            <template v-slot:item.orderId="{ item }">
              <div>
                {{ item.orderId }}
              </div>
            </template>

            <template v-slot:item.orderAmount="{ item }">
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                {{ item.orderAmount / 100.0 }}
              </span>
            </template>
            <template v-slot:item.merchantId="{ item }">
              <div>{{ item.merchantName }} [{{ item.merchantId }}]</div>
            </template>

            <template v-slot:item.channelId="{ item }">
              <div>{{ item.channelName }} [{{ item.channelId }}]</div>
            </template>

            <template v-slot:item.payType="{ item }">
              {{ payTypeMap[item.payType] }}
            </template>

            <template v-slot:item.createTime="{ item }">
              <div>
                {{ item.createTime | formatDate }}
              </div>
            </template>

            <template v-slot:item.payTime="{ item }">
              <div>
                {{ item.payTime | formatDate }}
              </div>
            </template>

            <template v-slot:item.platformIncome="{ item }">
              <span v-if="item.isSettle == 0"> 待结算 </span>
              <span v-else-if="item.isSettle == 1">
                {{ item.platformIncome / 100.0 }}
              </span>
            </template>

            <template v-slot:item.merchantIncome="{ item }">
              <span v-if="item.isSettle == 0"> 待结算 </span>
              <span v-else-if="item.isSettle == 1">
                {{ item.merchantIncome / 100.0 }}
              </span>
            </template>

            <template v-slot:item.status="{ item }">
              <b-badge variant="secondary">
                {{ statusMap[item.status] }}
              </b-badge>
            </template>

            <template v-slot:item.isNotify="{ item }">
              <span v-if="item.isNotify == 0"> 待回调 </span>
              <span v-else-if="item.isNotify == 1"> 已回调 </span>
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="手动回调"
                v-on:click="manualNotify(item.orderId)"
              >
                <v-icon>la la-reply</v-icon>
              </v-btn>
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="订单详情"
                v-on:click="showDetail(item.id)"
              >
                <v-icon>la-poll-h</v-icon>
              </v-btn>
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="变更状态"
                v-if="
                  item.status == 1 ||
                  item.status == 3 ||
                  item.status == 5 ||
                  item.status == 6 ||
                  item.status == 7
                "
                v-on:click="changeStatus(item)"
              >
                <v-icon>la-gear</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form>
                <b-row class="m-0 p-0">
                  <b-col class="m-0 ml-2 p-0">
                    <b-row class="m-0 p-0">
                      <b-col cols="5" class="m-0 p-0">
                        <b-form-select
                          :options="filter.conditions"
                          v-model="filter.field"
                        ></b-form-select>
                      </b-col>
                      <b-col cols="7" class="m-0 p-0 pl-1">
                        <b-form-input
                          style="width: 100% !important"
                          v-model="filter.keyword"
                          placeholder="检索关键词"
                        >
                        </b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col class="m-0 ml-3 p-0">
                    <b-row class="m-0 p-0">
                      <b-col class="m-0 p-0">
                        <v-autocomplete
                          v-model="filter.merchantId"
                          :items="merchants"
                          label="商户"
                          auto-select-first
                          outlined
                          dense
                          chips
                          small-chips
                          clearable
                          item-text="merchantName"
                          item-value="merchantId"
                          hide-details="true"
                          allow-overflow="false"
                        >
                          <template v-slot:no-data>
                            <span class="ml-4 text-dark-50">暂无匹配商户</span>
                          </template>
                        </v-autocomplete>
                      </b-col>
                      <b-col class="m-0 p-0 pl-1">
                        <v-autocomplete
                          v-model="filter.channelId"
                          :items="channels"
                          label="通道"
                          auto-select-first
                          outlined
                          dense
                          chips
                          small-chips
                          clearable
                          item-text="channelName"
                          item-value="interfaceId"
                          hide-details="true"
                          allow-overflow="false"
                        >
                          <template v-slot:no-data>
                            <span class="ml-4 text-dark-50"
                              >暂无匹配供通道</span
                            >
                          </template>
                        </v-autocomplete>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col class="m-0 ml-3 p-0">
                    <date-picker
                      range
                      v-model="filter.dateRange"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      popper-class="datetime-popper"
                      value-class="datetime-picker-input"
                    >
                    </date-picker>
                  </b-col>

                  <b-col class="m-0 ml-2 p-0">
                    <b-row class="m-0 p-0">
                      <b-col cols="5" class="m-0 p-0">
                        <b-form-select
                          :options="filter.statusOptions"
                          v-model="filter.status"
                        ></b-form-select>
                      </b-col>
                      <b-col cols="5" class="m-0 p-0">
                        <b-button
                          class="ml-2"
                          variant="success"
                          v-on:click="search()"
                        >
                          <v-icon small> flaticon-search </v-icon>
                          查询
                        </b-button>
                        <v-btn
                          v-if="filter.tooltips_visible"
                          color="success"
                          v-on:click="filter.tooltips_visible = false"
                          fab
                          small
                          class="ml-2"
                        >
                          <v-icon>fas fa-chevron-up</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          color="success"
                          v-on:click="filter.tooltips_visible = true"
                          fab
                          small
                          class="ml-2"
                        >
                          <v-icon>fas fa-chevron-down</v-icon>
                        </v-btn>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>

              <b-row class="ml-2 mt-1 mb-0">
                <b-col cols="12" class="m-0 p-0">
                  <b-collapse v-model="filter.tooltips_visible">
                    <b-row class="m-0 p-0">
                      <b-col cols="12" class="m-0 mt-1 mb-2 p-0">
                        <div class="separator separator-dashed"></div>
                      </b-col>

                      <b-col cols="12" class="m-0 p-0">
                        <div class="d-flex align-items-center flex-wrap mt-0">
                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 订单总量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.totalCount || 0 }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 订单总金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold"
                                  >$</span
                                >
                                {{ table.statistic.totalAmount / 100.0 || 0.0 }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 成功数量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.successCount || 0 }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 成功金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold"
                                  >$</span
                                >
                                {{
                                  table.statistic.successAmount / 100.0 || 0.0
                                }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 失败数量 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                {{ table.statistic.failCount }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 失败金额 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span class="text-dark-50 font-weight-bold"
                                  >$</span
                                >
                                {{ table.statistic.failAmount / 100.0 || 0.0 }}
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-items-center flex-lg-fill mr-5"
                          >
                            <span class="mr-4"> 成功率 </span>
                            <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-h5">
                                <span
                                  class="text-dark-50 font-weight-bold"
                                ></span>
                                {{
                                  table.statistic.successRate
                                    ? table.statistic.successRate.toFixed(2)
                                    : 0.0
                                }}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-col>
              </b-row>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-更改订单状态-->
    <b-modal ref="status-modal" static="true" centered title="更改订单状态">
      <b-container fluid>
        <b-form
          id="statusForm"
          method="POST"
          class="form"
          novalidate="novalidate"
        >
          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">订单号 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <b-form-input
                  type="text"
                  name="specs"
                  readonly
                  v-model="statusForm.orderId"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">状态 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <v-radio-group
                  class="mt-0"
                  row
                  name="status"
                  v-model="statusForm.status"
                  :mandatory="false"
                >
                  <v-radio label="支付成功" :value="4"></v-radio>
                  <v-radio label="支付失败" :value="6"></v-radio>
                </v-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['status-modal'].hide()">取消</b-button>
        <b-button v-on:click="submitChangeStatus()" variant="primary"
          >提交</b-button
        >
      </template>
    </b-modal>
    <!--模态框-更改订单状态-->

    <!--模态框-手动结算-->
    <b-modal ref="settle-modal" static="true" centered title="手动结算">
      <b-container fluid>
        <b-form
          id="settleForm"
          method="POST"
          class="form"
          novalidate="novalidate"
        >
          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">商户 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <input
                  class="form-control form-control-solid"
                  type="hidden"
                  name="merchantId"
                  v-model="settleForm.merchantId"
                />
                <v-autocomplete
                  v-model="settleForm.merchantId"
                  :items="merchants"
                  label="商户"
                  auto-select-first
                  outlined
                  dense
                  chips
                  small-chips
                  clearable
                  item-text="merchantName"
                  item-value="merchantId"
                  hide-details="true"
                  allow-overflow="false"
                >
                  <template v-slot:no-data>
                    <span class="ml-4 text-dark-50">暂无匹配商户</span>
                  </template>
                </v-autocomplete>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">截止时间 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <date-picker
                  v-model="settleForm.lastTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  popper-class="datetime-popper"
                  value-class="datetime-picker-input"
                  placeholder="截止时间"
                >
                </date-picker>
                <v-btn
                  class="ml-2"
                  small
                  text
                  icon
                  fab
                  v-b-tooltip.hover.lefttop
                  title="查询待结算总金额"
                  v-on:click="queryUnSettleAmount()"
                >
                  <v-icon>fab fa-sistrix</v-icon>
                </v-btn>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">待结算金额 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <b-input v-model="unSettleAmount" disabled></b-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['settle-modal'].hide()">取消</b-button>
        <b-button v-on:click="submitSettle()" variant="primary">提交</b-button>
      </template>
    </b-modal>
    <!--模态框-手动结算-->

    <!--模态框-订单详情-->
    <b-modal
      ref="detail-modal"
      size="lg"
      static="true"
      centered
      title="订单详情"
    >
      <b-container fluid>
        <b-row>
          <b-col class="p-0 m-0 mr-1">
            <b-list-group>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">平台订单号</b-badge>
                {{ currentOrder.orderId }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">商户名称</b-badge>
                {{ currentOrder.merchantName }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">订单金额</b-badge>
                {{ currentOrder.orderAmount / 100.0 }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">商品名称</b-badge>
                {{ currentOrder.product }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">回调地址</b-badge>
                {{ currentOrder.notifyUrl }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">扩展参数</b-badge>
                {{ currentOrder.ext }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道订单号</b-badge>
                {{ currentOrder.channelOrderId }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道状态码</b-badge>
                {{ currentOrder.channelResultCode }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">订单创建时间</b-badge>
                {{ currentOrder.orderTime | formatDate }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">订单结算时间</b-badge>
                {{ currentOrder.settleTime | formatDate }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道响应耗时</b-badge>
                {{ currentOrder.delayTime  }} 毫秒
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col class="p-0 m-0 ml-1">
            <b-list-group>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">商户订单号</b-badge>
                {{ currentOrder.outOrderId }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道名称</b-badge>
                {{ currentOrder.channelName }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">手续费</b-badge>
                {{ currentOrder.tradeFee }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">商品描述</b-badge>
                {{ currentOrder.describe }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">跳转地址</b-badge>
                {{ currentOrder.returnUrl }}
              </b-list-group-item>

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">下单IP</b-badge>
                {{ currentOrder.sourceIp }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">订单状态</b-badge>
                {{ statusMap[currentOrder.status] }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">通道返回结果</b-badge>
                {{ currentOrder.channelResultMsg }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">回调次数</b-badge>
                {{ currentOrder.notifyTimes }}
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge class="mr-1" variant="light">订单支付时间</b-badge>
                {{ currentOrder.payTime | formatDate }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['detail-modal'].hide()">关闭</b-button>
      </template>
    </b-modal>
    <!--模态框-订单详情-->
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import Vue from "vue";
import Qs from "qs";
import Moment from "moment";

import DatePicker from "@wangankeji/vue-datepicker";
import "@wangankeji/vue-datepicker/dist/datepicker.css";
export default {
  name: "List",
  components: {
    KTCard,
    DatePicker,
  },
  props: {
    title: String,
  },
  data() {
    return {
      timer: null,
      merchants: [],
      channels: [],
      filter: {
        tooltips_visible: false,
        conditions: [
          {
            value: "",
            text: "检索条件",
          },
          {
            value: "orderId",
            text: "平台订单号",
          },
          {
            value: "outOrderId",
            text: "商户订单号",
          },
          {
            value: "channelOrderId",
            text: "通道订单号",
          },
        ],
        statusOptions: [
          {
            value: null,
            text: "状态",
          },
          {
            value: 1,
            text: "下单成功",
          },
          {
            value: 2,
            text: "下单失败",
          },
          {
            value: 3,
            text: "支付中",
          },
          {
            value: 4,
            text: "支付成功",
          },
          {
            value: 5,
            text: "支付超时",
          },
          {
            value: 6,
            text: "支付失败",
          },
          {
            value: 7,
            text: "支付异常",
          },
        ],

        field: "",
        keyword: "",
        status: null,
        dateRange: [
          Moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ) + " 00:00:00",
          Moment(new Date()).format("YYYY-MM-DD") + " 23:59:59",
        ],
        channelId: "",
        merchantId: "",
      },
      statusMap: {
        1: "下单成功",
        2: "下单失败",
        3: "支付中",
        4: "支付成功",
        5: "支付超时",
        6: "支付失败",
        7: "支付异常",
      },
      payTypeMap: {
        101: "微信支付",
        201: "支付宝",
        301: "印度Pay",
        401: "巴西Pay",
      },
      auditForm: {},
      statusForm: {},
      settleForm: {},
      unSettleAmount: 0,
      currentOrder: {},
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 50,
        pagePack: {
          lastPage: null,
          firstId: null,
          lastId: null,
        },
        headers: [
          {
            text: "平台订单号",
            value: "orderId",
          },
          {
            text: "商户订单号",
            value: "outOrderId",
          },
          {
            text: "通道订单号",
            value: "channelOrderId",
          },
          {
            text: "商户",
            value: "merchantId",
          },
          {
            text: "订单金额",
            value: "orderAmount",
          },
          {
            text: "服务费",
            value: "tradeFee",
          },
          {
            text: "支付方式",
            value: "payType",
          },
          {
            text: "通道",
            value: "channelId",
          },

          {
            text: "订单状态 ",
            value: "status",
          },
          {
            text: "通知状态 ",
            value: "isNotify",
          },
          {
            text: "创建时间",
            value: "createTime",
          },
          {
            text: "支付时间",
            value: "payTime",
          },
          {
            text: "操作",
            value: "action",
          },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
        statistic: {},
      },
    };
  },
  created() {
    this.getMerchants();
    this.getChannels();
  },
  mounted() {
    let statusForm = KTUtil.getById("statusForm");
    this.fv1 = formValidation(statusForm, {
      fields: {
        status: {
          validators: {
            notEmpty: {
              message: "请选择变更状态!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

    let settleForm = KTUtil.getById("settleForm");
    this.fv2 = formValidation(settleForm, {
      fields: {
        merchantId: {
          validators: {
            notEmpty: {
              message: "请选择商户!",
            },
          },
        },
        lastDate: {
          validators: {
            notEmpty: {
              message: "请选择日期!",
            },
          },
        },
        lastTime: {
          validators: {
            notEmpty: {
              message: "请选择时间!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  methods: {
    debounce(func, delay) {
      console.info("timer:", this.timer);
      if (this.timer) {
        console.info("timer exist :", this.timer);
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(func, delay);
    },
  //"msg" -> "120.235.122.150ip is not whitelisted"
    exportOrders() {
      let startTime = this.filter.dateRange[0],
        endTime = this.filter.dateRange[1];
      // if (startTime) {
      //   startTime = startTime + " 00:00:00";
      // }
      // if (endTime) {
      //   endTime = endTime + " 23:59:59";
      // }

      let params = {
        channelId: this.filter.channelId,
        merchantId: this.filter.merchantId,
        status: this.filter.status,
        startTime: startTime,
        endTime: endTime,
      };

      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/order/export_orders", {
        params: params,
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },

    issue(orderId = undefined) {
      if (orderId == undefined) {
        Swal.fire({
          title: "",
          text: "请选择需要处理的订单!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      ApiService.query("/api/order/manage/query_by_id", {
        params: {
          orderId: orderId,
        },
      }).then(({ data }) => {
        that.auditForm = data.result;
        that.$nextTick(() => {
          let fv = that["fv1"];
          if (fv != undefined) {
            fv.destroy();
          }

          let auditForm = KTUtil.getById("auditForm");
          that.fv1 = formValidation(auditForm, {
            fields: {
              status: {
                validators: {
                  notEmpty: {
                    message: "请选择修改后订单状态!",
                  },
                },
              },
            },
            plugins: {
              trigger: new Trigger(),
              bootstrap: new Bootstrap(),
            },
          });

          if (that.auditForm.orderType == 2) {
            that["fv1"].addField("supplierId", {
              validators: {
                notEmpty: {
                  message: "请选择供货商!",
                },
              },
            });
            that["fv1"].addField("specs", {
              validators: {
                notEmpty: {
                  message: "请输入尺码!",
                },
              },
            });
            that["fv1"].addField("currentPrice", {
              validators: {
                notEmpty: {
                  message: "请输入价格!",
                },
              },
            });
            console.info(that.fv1);
          }

          that.$refs["audit-modal"].show();
        });
      });
    },

    queryUnSettleAmount() {
      let that = this;
      let settleForm = {
        merchantId: that.settleForm.merchantId,
        lastTime: that.settleForm.lastTime,
      };

      Vue.axios
        .post("/api/order/manage/query/unSettle", Qs.stringify(settleForm), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(({ data, status, statusText, headers }) => {
          if (data.success) {
            that.unSettleAmount = data.result / 100.0;
          }
        });
    },

    submitSettle() {
      let that = this;

      this.fv2.validate().then(function (status) {
        if (status === "Valid") {
          let settleForm = {
            merchantId: that.settleForm.merchantId,
            lastTime: that.settleForm.lastTime,
          };
          Vue.axios
            .post("/api/order/manage/manual/settle", Qs.stringify(settleForm), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(({ data, status, statusText, headers }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "提交成功！",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
                that.$refs["settle-modal"].hide();
              } else {
                Swal.fire({
                  title: "提交失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            });
        }
      });
    },

    changeStatus(item) {
      this.statusForm.id = item.id;
      // this.statusForm.orderId = item.orderId;
      this.$set(this.statusForm, "orderId", item.orderId);
      this.$refs["status-modal"].show();
    },

    submitChangeStatus() {
      let that = this;

      this.fv1.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(
            "/api/order/manage/change_status",
            that.statusForm
          ).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "提交成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["status-modal"].hide();
            } else {
              Swal.fire({
                title: "提交失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },

    manualSettle() {
      this.$refs["settle-modal"].show();
    },

    manualNotify(orderId = undefined) {
      orderId = orderId == undefined ? this.getSelectedIds() : [orderId];
      if (orderId.length < 1) {
        Swal.fire({
          title: "",
          text: "请选择要回调的订单!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      Swal.fire({
        title: "确定执行回调该订单?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          ApiService.post("/api/order/manual/notify", orderId).then(
            ({ data }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "回调成功",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
              } else {
                Swal.fire({
                  title: "回调失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            }
          );
        }
      });
    },

    search() {
      this.table.pagePack = {};
      if (this.table.pagination.page == 1) {
        this.getData();
      }
      this.table.pagination.page = 1;
    },

    getData() {
      let startTime = this.filter.dateRange[0],
        endTime = this.filter.dateRange[1];
      // if (startTime) {
      //   startTime = startTime + " 00:00:00";
      // }
      // if (endTime) {
      //   endTime = endTime + " 23:59:59";
      // }

      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        channelId: this.filter.channelId,
        merchantId: this.filter.merchantId,
        status: this.filter.status,
        startTime: startTime,
        endTime: endTime,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;
      params["pack.lastPage"] = this.table.pagePack.lastPage;
      params["pack.firstId"] = this.table.pagePack.firstId;
      params["pack.lastId"] = this.table.pagePack.lastId;

      ApiService.query("/api/order/query_list", {
        params: params,
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.pagePack = data.result.pack;
        this.table.itemsCount = data.result.total;
        this.table.statistic = data.result.statistic;
      });
    },

    showDetail(id) {
      let that = this;
      ApiService.query("/api/order/query_by_id", {
        params: {
          id: id,
        },
      }).then(({ data }) => {
        that.currentOrder = data.result;
        this.$refs["detail-modal"].show();
      });
    },

    getMerchants() {
      let params = {
          noPaging: true,
          status: 1,
        },
        that = this;
      ApiService.query("/api/merchant/manage/query_list", {
        params: params,
      }).then(({ data }) => {
        that.merchants = data.result.data;
      });
    },

    getChannels() {
      let params = {
          noPaging: true,
          status: 1,
        },
        that = this;
      ApiService.query("/api/channel/interface/manage/query_list", {
        params: params,
      }).then(({ data }) => {
        that.channels = data.result.data;
      });
    },

    getSelectedIds() {
      let ids = new Array();
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.orderId);
        });
      }
      return ids;
    },
  },

  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "filter.dateRange": {
      handler: function () {
        this.search();
      },
      immediate: false,
    },
   
    "filter.status": {
      handler: function () {
        this.search();
      },
    },
    "filter.merchantId": {
      handler: function () {
        this.search();
      },
      immediate: false,
    },
    "filter.channelId": {
      handler: function () {
        this.search();
      },
      immediate: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .date-picker--popper-dialog {
//   z-index: 98 !important;
// }

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.product_quantity {
  font-size: 16px !important;
  font-weight: 900 !important;
}

.dropdown-menu {
  padding-left: 0px !important;
}

.dropdown-menu li {
  padding-left: 0px !important;
}

.img-cover {
  object-fit: contain !important;
  max-width: 120px !important;
}
</style>
